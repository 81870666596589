<template>
	<v-layout v-if="localTask" fill-height column>
		<!-- Title Bar -->
		<TasksManagerTaskDetailsToolbar :original-task="task" :task="localTask" @task-update="onTaskUpdate" @close="$emit('close')" />
		<v-flex scroll-y>
			<v-layout fill-height column px-3>
				<v-flex shrink>
					<TasksManagerTaskDetailsParentTaskRecall :task="task" />
				</v-flex>
				<v-flex shrink>
					<TasksManagerTaskContent v-model="localTask" />
				</v-flex>
				<!-- Tabs -->
				<v-flex fill-height scroll-y>
					<TasksManagerTaskDetailsTabs :task="task" />
				</v-flex>
				<!-- Bottom line: creation date + author + modification date -->
				<v-flex shrink>
					<TasksManagerTaskDetailsAuthors :task="task" />
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerTaskDetails',
	components: {
		TasksManagerTaskDetailsToolbar: () => ({
			component: import('@/components/TasksManager/Details/TasksManagerTaskDetailsToolbar')
		}),
		TasksManagerTaskDetailsParentTaskRecall: () => ({
			component: import('@/components/TasksManager/Details/TasksManagerTaskDetailsParentTaskRecall')
		}),
		TasksManagerTaskContent: () => ({
			component: import('@/components/TasksManager/Task/TasksManagerTaskContent')
		}),
		TasksManagerTaskDetailsTabs: () => ({
			component: import('@/components/TasksManager/Details/TasksManagerTaskDetailsTabs')
		}),
		TasksManagerTaskDetailsAuthors: () => ({
			component: import('@/components/TasksManager/Details/TasksManagerTaskDetailsAuthors')
		})
	},
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			localTask: null
		}
	},
	computed: {
		...mapState({
			accountants: state => state.tasks.members,
			companies: state => state.tasks.companies
		}),
		isMainTask: function () {
			return !this.task.isChild
		},
		isInternal: function () {
			return !this.task.vendor
		},
		themeList: function () {
			return this.isInternal ? [] : this.companies.filter(company => company.id === this.task.vendor.id).flatMap(company => company.themes)
		},
		memberList: function () {
			return this.isInternal ? this.accountants : []
		}
	},
	watch: {
		task: {
			deep: true,
			handler: function (newValue) {
				this.localTask = this.getContext(newValue)
			}
		}
	},
	created: function () {
		this.localTask = this.getContext(this.task)
	},
	methods: {
		getContext: function (task) {
			const taskCopy = { ...task }
			if (task && task.members) {
				taskCopy.members = [...task.members]
			}
			if (task && task.themes) {
				taskCopy.themes = [...task.themes]
			}
			return taskCopy
		},
		onTaskUpdate: function (updatedTask) {
			this.localTask = updatedTask
		}
	}
}
</script>
